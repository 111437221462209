<template>
	<div class="d-flex flex-column h-100">
		<div class="search-element ">
			<el-form :inline="true" class="d-flex align-items-center gap-3" @submit.native.prevent="changeKeyword">
				<el-input v-model="productKeyword" :debounce="2000" class="flex-grow-1 " placeholder="Cari Produk"
					clearable>
					<img slot="prefix" class="icon-search" src="@/assets/icons/icon-search.svg" alt="Icon Search">
				</el-input>
				<button class="btn btn-primary font-14 font-nunito font-700 text-white my-auto py-2 px-4">Cari</button>
			</el-form>
		</div>
		<category-list />
		<div class="d-flex mb-3">
			<div class="font-nunito font-16 font-700">
				Produk
			</div>
			<div class="d-flex ms-auto my-auto">
				<el-dropdown trigger="click" @command="changeSort">
					<span class="font-12 font-roboto font-400 color-black">
						Urut Berdasarkan <span class="font-700">{{ selectedSort.name }}</span> <i
							class="el-icon-arrow-down el-icon--right font-700 font-12"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item :command="sort" v-for="sort in listSorting" :key="sort.slug">{{ sort.name
							}}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<div class="row mx-m-15 height " :class="{ 'align-items-start': products.products.length < 5 }"
			v-infinite-scroll="getProductList"
			:infinite-scroll-disabled="page + 1 > products.last_page"
			:infinite-scroll-immediate="false" style="overflow:auto">
			<template v-if="products.products.length > 0 && !productLoading">
				<div v-for=" (product, index) in products.products " :key="product.id + index" class=" col-3 mb-4 px-2">
					<div class="card-product d-flex flex-column" @click="selected(product)" data-bs-toggle="modal"
						:data-bs-target="product.total_stock ? shiftId ? '#PopupAddToCart' : '#startShift' : ''">
						<div class="image mx-auto mb-3 position-relative">
							<div class="position-absolute no-stock w-100 color-white font-500"
								v-if="!product.total_stock">
								Stok Habis
							</div>
							<img :src="product.files.value_thumb ? product.files.value_thumb : product.files.value"
								class="img-fluid" :class="{ 'img-no-stock': !product.total_stock }"
								@error="defaultImageProduct">
						</div>
						<div class="description text-center">

							<div class="product-badge type mb-2"
								:class="{ 'product-badge--combination': product.type === 'combination' }">
								{{ product.type }}
							</div>
							<span class="font-nunito font-14 font-700 product-name ">
								{{ product.name }}
							</span>
							<span class="font nunito font-14 font-700 text-primary mb-2">
								{{ product.brand_name }}
							</span>
							<div class="d-flex items-center gap-1 product-price">
								<span class="font-roboto font-10 font-400 color-gray-60" v-if="product.discount_amount">
									<s>{{ formatRupiah(product.price, 'Rp.') }}</s>
								</span>
								<span class="font-roboto font-12 font-400">
									{{ product.discount_amount ? formatRupiah(product.discounted_price, 'Rp.') :
										formatRupiah(product.price || 0, 'Rp.') }}
								</span>
							</div>

						</div>
					</div>
				</div>
			</template>

			<template v-else-if="products.products.length === 0 && !productLoading && productKeyword">
				<div class="d-flex flex-fill px-3">
					<div class="m-auto">
						<img src="@/assets/illustration/not-found-illustration.svg" class="w-100 not-found-illustration"
							alt="Illustration Empty">
						<div class="mb-3 text-center">
							<span class="font-nunito font-700 font-20">
								Tidak ditemukan untuk "{{ filterQuery.keyword }}"
							</span>
						</div>
						<div class="text-center">
							<span class="font-roboto font-16 d-block">
								Coba periksa kembali kata kunci yang Anda masukkan atau coba kata kunci lain
							</span>
						</div>
					</div>
				</div>
			</template>
			<template v-else-if="products.products.length === 0 && !productLoading && !productKeyword">
				<div class="d-flex flex-fill px-3">
					<div class="m-auto">
						<img src="@/assets/illustration/not-found-illustration.svg" class="w-100 not-found-illustration"
							alt="Illustration Empty">
						<div class="mb-3 text-center">
							<span class="font-nunito font-700 font-20">
								Tidak ditemukan untuk Produk dengan kategory dipilih
							</span>
						</div>
						<div class="text-center">
							<span class="font-roboto font-16 d-block">
								Coba periksa kembali kategori yang Anda masukkan atau coba kategori lain
							</span>
						</div>
					</div>
				</div>
			</template>
			<template v-if="productLoading">
				<product-list-skeleton v-for=" skeleton in 12 " :key="skeleton.id" />
			</template>
			<template v-if="loadMoreProductLoading">
				<product-list-skeleton class="col-3" v-for=" skeleton in 4 " :key="skeleton.id" />
			</template>
		</div>
	</div>
</template>

<script>
import IhsMixin from "@/mixins/ihs.mixins.js";
import ProductListSkeleton from "@/components/skeleton/ProductListSkeleton.vue";
import CategoryList from "@/components/partials/Category.vue";

import { mapState, mapMutations, mapActions } from "vuex";

export default {
	mixins: [IhsMixin],
	components: {
		CategoryList,
		ProductListSkeleton
	},
	data() {
		return {
			disable: false,
			pageSize: 12,
			page: 0,
			product_ids: [],
			totalPage: 1,
			productKeyword: "",
			handleLoading: true,
			selectedSort: {
				name: "Terbaru",
				type: "default"
			},
			listSorting: [
				{
					name: "Terbaru",
					type: "default"
				},
				{
					name: "Terlama",
					type: "oldest"
				},
				{
					name: "Terlaris",
					type: "product_sold"
				},
				{
					name: "Terpopuler",
					type: "most_viewed"
				},
				{
					name: "Termurah",
					type: "cheapest"
				},
				{
					name: "Termahal",
					type: "priciest"
				}
			]
		};
	},
	computed: {
		...mapState("home", [
			"sidebarItemActive",
			"filterProduct",
			"filterProductItem"
		]),
		...mapState("shift", ["shiftId"]),
		...mapState("product", [
			"products",
			"productLoading",
			"loadMoreProductLoading"
		]),
		filterQuery() {
			return this.$route.query;
		}
	},
	methods: {
		...mapActions("product", ["getProducts", "getProduct"]),
		...mapMutations('product', ['SELECT_PRODUCT', 'SET_PRODUCT_LOADING', 'SET_LOAD_MORE_PRODUCT_LOADING']),
		...mapMutations("home", ["setFilterProduct"]),
		checkFilteredQuery() {
			if (this.filterQuery.keyword) {
				this.productKeyword = this.filterQuery.keyword;
			}
			if (this.filterQuery.sort) {
				this.selectedSort = this.listSorting.filter(sorting => {
					return sorting.type === this.filterQuery.sort;
				})[0];
			}
		},
		async changeSort(sort) {
			this.$emit("resetPage", () => {
				this.selectedSort = sort;
				if (this.$router.currentRoute.query.sort !== sort.type) {
					this.setToQuery({ sort: sort.type });
				}
			});
		},
		changeKeyword() {
			this.page = 0
			if (this.$router.currentRoute.query.keyword !== this.productKeyword) {
				this.setToQuery({ keyword: this.productKeyword });
			}

		},

		selected(product) {
			if (product.total_stock) {
				this.getProduct(product.slug);
			}
		},
		async getProductList() {
			if (this.page + 1 === 1) {
				this.SET_PRODUCT_LOADING(true)
			} else {
				this.SET_LOAD_MORE_PRODUCT_LOADING(true)
			}

			this.timeout = setTimeout(async () => {
				this.page++
				try {
					const payload = {
						params: {
							search: this.filterQuery.keyword ? this.filterQuery.keyword : '',
							filter: this.filterQuery.sort ? this.filterQuery.sort : this.selectedSort.type,
							category: this.filterQuery.category ? this.filterQuery.category : '',
							page: this.page,
							perPage: 8,
							zero_stock_at_end: true
						},
					}
					await this.getProducts(payload)

				} catch (error) {
					this.$message({
						showClose: true,
						customClass: "error-message",
						message: error.response.data.message,
						type: "error"
					});
				}
			}, 1000);
		},
	},
	async created() {
		await this.checkFilteredQuery();
		await this.getProductList();
	}
};
</script>

<style lang="scss" scoped>
.filter-list,
.dropdown-menu {
	cursor: pointer;
}

.no-stock {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(31, 31, 31, 0.5);
	padding: 6px 10px;
	border-radius: 6px;
	z-index: 20;
}

.img-no-stock {
	opacity: 0.5;
	z-index: 10;
}

.height {
	height: 620px;
	justify-items: start;
	justify-content: start;
	overflow: auto;
}

.product-badge {
	padding: 3px 4px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 500;
	text-transform: capitalize;
	color: white;
	width: max-content;
	margin: 0 auto;
	background-color: #17A2B8;

	&--combination {
		background-color: #FFC107;
	}
}

.product-price {
	justify-content: center;
}

.product-name {
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	/* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;

}
</style>